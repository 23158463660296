import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Head from '../components/head';

const About = () => {
  return (
    <Layout>
      <Head title="Contact" />
      <h1>Contact</h1>
      <p>
        Talk about survey now being completed. Still accept reports via email.
        Need to include...
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam pharetra
        condimentum quam, ac mollis enim. Fusce a quam dolor.
      </p>
      <p>
        Aenean faucibus auctor molestie. Ut posuere nunc mauris, nec semper
        mauris pretium at. Curabitur enim mi, consequat vel tempus vel, luctus
        sed nisl.
      </p>
      <p>
        Sed erat ipsum, semper tincidunt porta sit amet, aliquam nec augue.
        Etiam vulputate ligula at bibendum elementum.
      </p>

      <p>
        All the extra traffic, congested, dangerous. People crawling as they
        enter Hollingdean, looking for a space to park in.
      </p>
    </Layout>
  );
};

export default About;
